import React, { Component } from 'react'
import { Link } from "react-scroll";
import Navbar from 'react-bootstrap/Navbar'
import api from './api.js'
import {
    Button,
    Form,
    Nav,
    Container,
    Offcanvas,
    FormControl
} from 'react-bootstrap';

class Headers extends Component {
    state = {
        categorias: [],
    }

    async componentDidMount() {
        const response = await api.get('categorias');
        this.setState({
            categorias: response.data,
        })
    }




    render() {
        const { categorias } = this.state;

        return (
            <div>
                <Navbar bg="light" expand={false} fixed="top">
                    <Container fluid>
                        <Navbar.Brand href="#home">
                            <img
                                src="/Logo512.png"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                alt="Logo Casa do Tambaqui"
                            />
                            Cardápio
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="offcanvasNavbar" />
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="offcanvasNavbarLabel">Categorias</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    {categorias.map(cat => (
                                        <Link
                                            key={cat.id}
                                            href={"#categoria_" + cat.id}
                                            to={"categoria_" + cat.id}
                                            activeClass="active"
                                            className="nav-link"
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                        >
                                            {cat.nome}
                                        </Link>
                                    ))}
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default Headers


{
    /*<Nav.Link key={cat.id} href={"#categoria_" + cat.id}>{cat.nome}</Nav.Link>*/
}