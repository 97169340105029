import './App.css';
import Headers from './Headers.js';
//import api from './api.js';
import Article from './Article.js';
//import Footer from './Footer.js';
import React, { Component } from 'react';

class App extends Component {

  render() {
    return (
      <div>
        <Headers></Headers>
        <Article></Article>
      </div>
    );
  }
}


export default App;
