import axios from 'axios';

const api = axios.create({
    baseURL: 'https://www.casadotambaquipvh.com.br/api/',
    timeout: 9000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

export default api;