import React, { useState, useEffect, Component } from 'react'
import api from './api.js'
import {
    Badge,
    Button,
    Card,
    ListGroup,
    ListGroupItem,
    Accordion,
} from 'react-bootstrap';

class Article extends Component {
    state = {
        catalogos: [],
    }

    async componentDidMount() {
        const response = await api.get('produtosporgrupo')
        this.setState({
            catalogos: response.data,
        })

    }


    render() {
        const { catalogos } = this.state;
        console.log('catalogos')
        console.log(catalogos)

        function getProdutos(cat) {
            const produto = cat.produtos.map(prod => (
                <div key={"produto_" + prod.id} className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch py-3">
                    <Card>
                        <div className="row">
                            <div className="col">
                                <Card.Img variant="top" src={"/Imagens/" + prod.foto} />
                                {/*<Card.Img variant="top" src={"https://www.casadotambaquipvh.com.br/Imagens/" + prod.foto} />*/}
                            </div>
                            <div className="col-8">
                                <Card.Body className="p-0">
                                    <Card.Title>{prod.nome}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{prod.valorCardapio.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Card.Subtitle>
                                    <Card.Text>
                                        {prod.descricaoResumida}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </div>

                        <span className="border-min border-bottom border-5 border-laranjo"></span>
                        <Accordion>
                            {prod.modalidades.map(m => (
                                <Accordion.Item key={m.modalidade.id} eventKey={m.modalidade.id}>
                                    <Accordion.Header>{m.modalidade.nome} (até {m.modalidade.quantidadeMaxima})</Accordion.Header>
                                    <Accordion.Body>
                                        <ListGroup className="list-group-flush">
                                            {m.itens.map(i => (
                                                <ListGroupItem key={i.id}>
                                                    {i.nome}
                                                    <Badge pill bg="secondary" className="float-end">
                                                        {i.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Badge>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}

                        </Accordion>
                        {/*<LoadingButton />*/}
                    </Card>
                </div>
            ))
            return produto
        }

        function simulateNetworkRequest() {
            return new Promise((resolve) => setTimeout(resolve, 2000));
        }

        function LoadingButton() {
            const [isLoading, setLoading] = useState(false);

            useEffect(() => {
                if (isLoading) {
                    simulateNetworkRequest().then(() => {
                        setLoading(false);
                    });
                }
            }, [isLoading]);

            const handleClick = () => setLoading(true);

            return (
                <Button
                    variant="outline-larango"//larango
                    disabled={isLoading}
                    onClick={!isLoading ? handleClick : null}
                >
                    {isLoading ? 'Selecionando...' : 'Selecionar'}
                </Button>
            );
        }

        //render(<LoadingButton />);

        return (
            <div className="container mt-5 pb-5 mb-5">
                {catalogos.map(cat => (
                    <div key={cat.categoria.id}>
                        <h1 id={"categoria_" + cat.categoria.id} className="py-4">
                            <span className="border-4 border-bottom border-laranjo">{cat.categoria.nome}</span>
                        </h1>
                        <div className="row">
                            {getProdutos(cat)}
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default Article

